.root {
    border: 1px solid #34363D;
    border-radius: 5px;
    max-width: 100%;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
}

.root:hover {
    border: 1px solid white;
}

.header {
    display: flex;
    border-bottom: 1px solid #34363D;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.title {
    margin: 0;
    color: white;
    opacity: 0.8;
    font-size: 12px;
    font-weight: 400;
    padding: 5px;
}

.header:hover {
    background-color: #A55A29;
}

.body {}

.table {
    border-collapse: collapse;
    width: 100%;
}

.tr {}

.td {
    padding-inline: 8px;
    text-align: left;
    color: #999CA2;
    cursor: pointer;
}

.td:hover {
    color: white;
}

.td:first-child {
    border-right: 1px solid #34363D;
}

.head {
    color: #A55A29 !important;
}

.head:hover {
    color: #da6c23 !important;
}