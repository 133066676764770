.bottom_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.database_icon {
  display: flex;
  align-items: center;
  background-color: var(--theme-send-icon);
  color: var(--theme-database-button);
  font-size: 0.875rem;
  border-radius: 8px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 10px;
}

.database_icon:hover {
  background-color: var(--theme-send-icon-hover-bg);
}

.send_icon {
  display: flex;
  align-items: center;
  background-color: var(--theme-send-icon);
  color: var(--theme-database-button);
  font-size: 0.875rem;
  border-radius: 8px;
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.send_icon:hover {
  background-color: var(--theme-send-icon-hover-bg);
}

.information {
  color: rgba(197, 197, 210, 1);
  opacity: 0.2;
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
}

.textarea_wrap {
  width: calc(var(--medium) + 20px);
  border-radius: 15px;
  padding: 5px 0;
  outline: none;
  background-color: var(--theme-page-bg);
  font-size: 24px;
  font-weight: 700;
  color: var(--theme-icon-color);
  border: 2px solid var(--theme-text-input-wrapper) !important;
}

@media only screen and (max-width: 1040px) {
  .textarea_wrap {
    width: 570px;
  }
}

@media only screen and (max-width: 876px) {
  .textarea_wrap {
    width: 495px;
    margin-inline: 10%;
  }
}

@media only screen and (max-width: 768px) {
  .textarea_wrap {
    width: 95%;
    margin-inline: 10%;
  }
}

.textarea_wrap textarea {
  color: var(--theme-page-text);
  font-size: 14px;
}

.textarea_wrap fieldset {
  display: none;
}

.textarea_wrap textarea::-webkit-scrollbar {
  width: 0px;
  height: 8px;
  display: none;
}
