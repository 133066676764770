.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: var(--theme-popup-bg);
    z-index: 3;
    border-radius: 5px;
}

.popup_inner {
    position: absolute;
    left: 30%;
    right: 30%;
    top: 15%;
    bottom: 25%;
    margin: auto;
    background: var(--theme-popup-inner-bg);
    border-radius: 7px;
    padding: 1.5rem;
    height: fit-content;
    color: var(--theme-page-text);
}

@media only screen and (max-width: 1100px) {
    .popup_inner {
        left: 18%;
        right: 18%;
    }
}

.main_content {
    max-height: 400px;
    overflow-y: auto;
    font-size: 15px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.main_content::-webkit-scrollbar {
    width: 0px;
    height: 8px;
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.main_content {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.content_wrap>div:first-child {
    border-color: #454343;
}

.tabs_wrap>div>div {
    justify-content: space-around;
}

.tabs_wrap button {
    color: var(--theme-page-text);
    text-transform: initial;
    font-size: 16px;
    font-weight: 600;
}