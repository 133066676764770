.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: var(--theme-popup-bg);
    z-index: 3;
    border-radius: 5px;
}

.popup_inner {
    position: absolute;
    left: 20%;
    right: 20%;
    top: 20%;
    bottom: 25%;
    margin: auto;
    /* background: white; */
    background: var(--theme-popup-inner-bg);
    border-radius: 7px;
    padding: 1.5rem;
    height: fit-content;
    color: var(--theme-page-text);
}

@media only screen and (max-width: 1100px) {
    .popup_inner {
        left: 18%;
        right: 18%;
    }
}


.container {
    /* width: 100%;
    max-width: 800px;
    margin: 0 auto;
    background-color: white; */
    /* display: flex; */
    /* width: 100%; */
    /* height: 100%; */
    /* background-color: white; */
  }
  .textColor{
    /* background-color: white;
    display: flex; */
    /* height: 100%; */
    /* color: black; */
  }
  
  .team_sec {
    background-color: #6553bf;
    color: #fff;
    padding: 20px;
    border-radius: 5px;
    min-height: 500px;
    position: relative;
  }
  
  .team_tags button {
    color: #fff;
    text-decoration: none;
    background-color: #5243aa;
    padding: 15px;
    display: inline-flex;
    flex-wrap: wrap;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 18px;
    border: none;
    outline: none;
    cursor:pointer;
  }
  .skipbtn {
    position: absolute;
    bottom: 30px;
    right: 40px;
    color: #b4b7ff;
    text-decoration: none;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 20px;
    cursor:pointer;
  }
  .skipAllBtn {
    position: absolute;
    bottom: 30px;
    left: 40px;
    color: #b4b7ff;
    text-decoration: none;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 20px;
    cursor:pointer;
  }
  .privacy {
    color: #6553bf;
    cursor:pointer;

  }
  
  
  /* Define styles for selected buttons */
  .selectedButton {
    background-color: white !important; /* Selected background color */
    color: black !important; /* Selected text color */
    border-color: #007bff; /* Selected border color */
  }
  /* CustomSlider.css */
  
  /* Style for the dots container */
  
  /* .slick_dots{bottom:-32px;right:0;z-index:3; height: 30px !important;} */
  /* .slick_dots li{position:relative;display:inline-block;width:10px;height:10px;margin:0 2.5px;padding:0;cursor:pointer;} */
  /* .slick_dots li button{width:10px;height:10px;padding:5px;} */
  .slick_dots li button:before {
    width: 12px  !important;;
    height: 12px  !important;;
    content: '' !important;
    opacity: 1;
    border-radius: 100%;
    background-color: #5243aa !important;
  }
  .slick_dots li.slick-active {
    width: 12px  !important;
    color: #b4b7ff !important;
  }
  /* .slick_dots li.slick-active button{width:14px;} */
  /* .slickwaper .slick-dots li button:before{color: transparent;} */
  
  /* p img {
    max-width: 25px;
    position: relative;
    top: 5px;
  } */
  
  