.input_wrap {
    width: 100%;
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    border: 1px solid var(--theme-input-field-border) !important;
    color: var(--theme-icon-color);
    background-color: var(--theme-input-field-bg);
}

.input_wrap svg {
    color: var(--theme-input-field-border)
}

.input_wrap fieldset {
    display: none;
}

.input_wrap input {
    height: 0.4rem;
    color: var(--theme-page-text);
    background-color: var(--theme-input-field-bg);
    font-size: 15px;
}

.input_wrap>div {
    min-height: 0.4rem !important;
    height: 0.4rem !important;
    padding: 8px 16px 24px 16px;
    color: var(--theme-page-text);
    font-size: 13px;
}

.label {
    font-size: 14px;
    color: var(--theme-input-title);
    margin-bottom: 3px;
}

.options {
    font-size: 14px;
}

.disabled_input {
    background-color: var(--theme-input-field-border)
}

.disabled_input svg {
    color: var(--theme-page-text) !important;
}

.disabled_input div {
    color: var(--theme-page-text) !important;
    -webkit-text-fill-color: var(--theme-page-text) !important;
}