.root{
  display: flex;
  flex-direction: row;
  align-items: center;
    cursor: pointer;

}
.svg {
  width: 25px !important;
  height: 25px !important;
  cursor: pointer;
  padding: 5px;
  color: var(--theme-white)
}
.postText {
  color: var(--theme-white)
}