.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--theme-popup-bg);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup_inner {
  background: var(--theme-popup-inner-bg);
  border-radius: 7px;
  height: 90%;
  width: 90%;
  color: var(--theme-page-text);
}

.close_icon {
  color: var(--theme-page-text-input);
  cursor: pointer;
  font-size: 2rem;
  float: right;
  position: absolute;
  top: 8px;
  right: 8px;
}

@media only screen and (max-width: 1100px) {
  .popup_inner {
    left: 18%;
    right: 18%;
  }
}

/* body{ background-color: #ccc; } */
.tabordion {
  position: relative;
  width: 100%;
  /* color: #30455c; */
  margin: 0;
  /* max-width: 1000px; */
  /* margin: 20px auto; */
  /* background-color: #fff; */
  border-radius: 20px;
  padding: 20px;
  height: 100%;
  overflow: hidden;
}
.tabordion section {
  display: block;
  width: 100%;
  left: 0;
}
.tabordion section input[name='sections'] {
  /* left: -9999px; */
  position: absolute;
  display: none;
  /* top: -9999px; */
}
.tabordion section input[name='sections']:checked + label {
  background: var(--theme-settings-checked);
  color: var(--theme-settings-checked-text);
  border-radius: 8px;
}
.tabordion section input[name='sections']:checked ~ article {
  display: block;
}
.tabordion section label {
  /* background: #fff; */
  /* border-bottom: 1px solid #22bec6; */
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  color: #838383;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: bold;
  padding: 1em 0.83em;
  position: relative;
  /* width: 100%; */
  z-index: 100;
  /* margin-bottom: 10px; */
}
.tabordion section article {
  display: none;
  left: 0;
  /* width: 100%; */
  padding: 1em;
  position: relative;
  top: 0;
  /* background: #fefffa; */
  font-family: 'Ubuntu', sans-serif;
  font-weight: 300;
  line-height: 1.7;
  border-radius: 20px;
}
.tabordion section article p {
  margin-bottom: 1em;
}
.tabordion section article:after {
  background-color: transparent;
  bottom: 0;
  content: '';
  display: block;
  left: -229px;
  position: absolute;
  top: 0;
  width: 220px;
  z-index: 1;
}
.slick-next {
  display: none !important;
}
.slick-prev {
  display: none !important;
}

@media all and (min-width: 768px) {
  .tabordion section label {
    /* background: rgba(220, 231, 235, 0.5); */
    /* border-right: 1px solid #22bec6; */
    padding: 1em;
    width: 20%;
    text-align: center;
  }
  .tabordion section article {
    position: absolute;
    width: 70%;
    left: 26.5%;
  }
}
