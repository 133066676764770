.root {
  justify-self: end;
  /* padding: 10px 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 6px;
  padding: 10px;
}
