* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

#root > div {
  width: 100% !important;
  height: 100% !important;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide default scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--theme-root-bg);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}

/* Handle when scrolling */
::-webkit-scrollbar-thumb:active {
  background: #888;
}

:hover::-webkit-scrollbar {
  display: block;
}

/* UTILS */

.flex_justify_items_start {
  display: flex;
  justify-content: start;
  flex-grow: 1;
}

.flex_justify_items_center {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.flex_justify_items_space_between {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.flex_align_items_center {
  align-items: center;
}

.flex_align_items_start {
  align-items: start;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.no_spacing {
  padding: 0 !important;
  margin: 0 !important;
}

.full_width {
  width: 100% !important;
}

/* Commons */

/* arrow buttons */

.arrow_button {
  color: var(--theme-page-text-secondary);
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.arrow_button:hover {
  background-color: var(--theme-active-tab-bg);
  border-radius: 5px;
}

.MuiTooltip-popper[data-popper-placement*='bottom']
  .css-ja5taz-MuiTooltip-tooltip {
  margin-top: 2px !important;
}

html {
  overscroll-behavior: none;
  background: #0d0d0d;
}

body {
  overscroll-behavior: none;
  overflow: hidden;
}
