.thumbButton {
  cursor: pointer;
  font-size: 1.1rem;
}

.thumbsUp_container {
  display: flex;
  flex-direction: row;
  gap: .2rem;
}

.feedBackButton {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
  padding: 5px;
  height: 48px;
}

.feedBackButton:hover {
  background-color: var(--theme-sidebar-list_hover);
}

.feedback_text {
 font-size: 16px;
 color: white;
 font-weight: 700;
}

.star_icon {
  margin-inline: 10px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
}