.name_text {
    font-size: 16px;
    font-weight: 600;
    display: flex;
}

.chatset {
    display: flex;
    flex-direction: row;
}

.icon_container {
    width: 40px;
}

.badge {
    background-color: var(--theme-avtar-workstream) !important;
    border-radius: 50%;
    border: 1px solid var(--theme-avtar-workstream-border);
    color: var(--theme-page-text);
    font-size: 14px;
    height: 24px;
    width: 24px;
    line-height: 24px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
}
.badge>svg {
    width: 20px;
    height: 20px;
}