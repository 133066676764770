.header {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    height: 50px;
    width: calc(100vw - var(--sidebar-width));
    background-color: var(--theme-page-bg);
    position: absolute;
    opacity: 0.8;
    padding-right: 20px;
    z-index: 10;
}

.header_mobile {
    display: flex;
    align-items: center;
    height: 40px;
    width: calc(100vw);
    background-color: var(--theme-page-bg);
    position: absolute;
    padding-right: 20px;
    z-index: 2;
    border-bottom: 1px solid rgba(197, 197, 210, 0.2);
}

.nav_img {
    fill: var(--theme-page-text);
    width: 20px;
    margin-left: 10px;
}


.download_btn {
    background-color: var(--theme-save-btn-bg);
    border: 1px solid var(--theme-popup-field-border);
    color: var(--theme-page-text);
    padding: 8px;
    border-radius: 10px;
    cursor: pointer;
}

@media only screen and (max-width: 786px) {
    .header {
        width: calc(100vw);
        height: 90px;
    }
}