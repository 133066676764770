.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  color: var(--theme-page-text);
  font-size: 14px;
}

/* .header {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    height: 50px;
    width: calc(100vw - var(--sidebar-width));
    background-color: var(--theme-page-bg);
    position: absolute;
    opacity: 0.8;
    padding-right: 20px;
    z-index: 10;
} */

/* .header_mobile {
    display: flex;
    align-items: center;
    height: 40px;
    width: calc(100vw);
    background-color: var(--theme-page-bg);
    position: absolute;
    padding-right: 20px;
    z-index: 2;
    border-bottom: 1px solid rgba(197, 197, 210, 0.2);
} */

/* @media only screen and (max-width: 786px) {
    .header {
        width: calc(100vw);
        height: 90px;
    }
} */

/* .nav_img {
    fill: var(--theme-page-text);
    width: 20px;
    margin-left: 10px;
} */

.chat_container {
  height: calc(100vh - 100px);
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding-inline: 5%;
  width: 100%;
  align-items: center;
  padding-bottom: 30px;
  gap: 5px;
  scroll-behavior: auto; /* Make sure it's not set to 'auto' */
}

.splash_bottom {
  padding: 10px 40px;
  position: relative;
  bottom: 20px;
  background-image: linear-gradient(
    180deg,
    var(--theme-gradiant-start),
    var(--theme-splash-screen-bg) 58.85%
  );
  padding-top: 30px;
}

.bottom_section input {
  border-radius: 25px !important;
}

.badge {
  background-color: var(--brand-dark-purple) !important;
  border-radius: 50%;
  border: 1px solid var(--brand-dark-purple);
  color: var(--theme-page-text);
  font-size: 14px;
  width: 35px;
  height: 35px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.logo {
  width: 35px;
  height: 35px;
  padding: 5px;
  border-radius: 50%;
  -webkit-filter: invert(1); /* safari 6.0 - 9.0 */
  filter: invert(1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge_icon {
  background-color: var(--theme-avtar-user) !important;
  border-radius: 50%;
  border: 1px solid var(--theme-avtar-user-border);
  color: var(--theme-page-text);
  font-size: 16px;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.name_text {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.seletced_chat_badge {
  background-color: var(--theme-selected-chat-avtar-bg);
}

.badge > svg {
  width: 20px;
  height: 20px;
}

.chatset {
  display: flex;
  align-items: center;
  gap: 8px;
}

.chat_box,
.bot_chat_box {
  display: flex;
  flex-direction: column;
  width: var(--medium);
  background-color: var(--theme-chat-box-bg);
  height: '100%';
}

.bot_chat_box {
}

@media only screen and (max-width: 1078px) {
  .chat_box,
  .bot_chat_box {
    width: 100%;
  }
}

.chat_content {
  margin-left: 50px;
  margin-top: 5px;
  overflow: hidden;
}

.chat_content code {
  white-space: break-spaces;
}

.chat_content > p {
  margin-top: 2px;
}

.chat_container::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  display: block;
}

.chat_container::-webkit-scrollbar-track {
  background: var(--theme-page-bg);
}

.btn_group {
  width: 80px;
  display: flex;
  align-items: center;
  height: fit-content;
  margin-left: 40px;
  visibility: hidden;
}

.bot_chat_box:hover .btn_group {
  visibility: visible;
}

.icon_container {
  width: 40px;
}

.btn_group > svg {
  margin-right: 1px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
}

.btn_group > svg:hover {
  background-color: var(--theme-avtar-bg);
}

.user_chat_content {
  width: 95%;
  margin-left: 50px;
  font-weight: 600;
}

.user_chat_content > p {
  white-space: pre-line;
  line-break: anywhere;
}

.spinning {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.activityMessage {
  margin-right: 10px;
}

.dot_typing {
  position: relative;
  left: -10019px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  animation: dot-typing 1.5s infinite linear;
}

.spacing_div {
  height: 100px;
}

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 var(--theme-page-text),
      9999px 0 0 0 var(--theme-page-text), 10014px 0 0 0 var(--theme-page-text);
  }

  16.667% {
    box-shadow: 9984px -10px 0 0 var(--theme-page-text),
      9999px 0 0 0 var(--theme-page-text), 10014px 0 0 0 var(--theme-page-text);
  }

  33.333% {
    box-shadow: 9984px 0 0 0 var(--theme-page-text),
      9999px 0 0 0 var(--theme-page-text), 10014px 0 0 0 var(--theme-page-text);
  }

  50% {
    box-shadow: 9984px 0 0 0 var(--theme-page-text),
      9999px -10px 0 0 var(--theme-page-text),
      10014px 0 0 0 var(--theme-page-text);
  }

  66.667% {
    box-shadow: 9984px 0 0 0 var(--theme-page-text),
      9999px 0 0 0 var(--theme-page-text), 10014px 0 0 0 var(--theme-page-text);
  }

  83.333% {
    box-shadow: 9984px 0 0 0 var(--theme-page-text),
      9999px 0 0 0 var(--theme-page-text),
      10014px -10px 0 0 var(--theme-page-text);
  }

  100% {
    box-shadow: 9984px 0 0 0 var(--theme-page-text),
      9999px 0 0 0 var(--theme-page-text), 10014px 0 0 0 var(--theme-page-text);
  }
}

.loader {
  margin-left: 36px;
  margin-top: 12px;
}

.error_message {
  background-color: var(--theme-error-banner-bg);
  color: var(--theme-chat-error-text);
  width: 81%;
  margin-left: 2%;
  padding: 2%;
  margin-right: 2px;
  border: 1px solid var(--theme-error-banner-border);
  line-break: anywhere;
}

.error_message > p {
  margin: 0px;
}

.error_message code {
  white-space: break-spaces;
}

.seletced_chat {
  background: var(--theme-selected-chat-bg);
}

/* .download_btn {
    background-color: var(--theme-save-btn-bg);
    border: 1px solid var(--theme-popup-field-border);
    color: var(--theme-page-text);
    padding: 8px;
    border-radius: 10px;
    cursor: pointer;
} */

.copy_code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #202123;
  padding: 8px;
  height: 30px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.scroll_down {
  display: flex;
  align-items: center;
  background-color: var(--theme-send-icon);
  border: 1px solid var(--theme-popup-field-border);
  color: var(--theme-page-text);
  /* padding: 9px 17px; */
  font-size: 0.875rem;
  border-radius: 4px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  opacity: 0.8;
  transition: transform 0.3s ease-in-out;
  margin-bottom: 10px;
}

.scroll_down:hover {
  transform: scale(1.1);
}

.language {
  color: var(--theme-white);
}

.table_title {
  margin: 0px;
  margin-bottom: 10px;
}

[data-testid='ArrowDropDownIcon'] {
  fill: white !important;
}
