.root {
    /* padding: 0px 40px 40px 40px; */
    margin-top: 5%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 55vh;
}

.header_wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.title {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
}

.data_content {
    position: absolute;
    bottom: 100px;
}

.col_icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.125rem;
}

.col_icon>svg {
    width: 24px;
    height: 24px;
    margin-bottom: 10px;
}

.col_chat>div {
    font-size: 14px;
    border-radius: 5px;
    background-color: var(--theme-splash-screen-chat-bg);
    margin: 0.875rem 0.437rem;
    padding: 0.75rem;
}

.col_chat_input>div {
    font-size: 14px;
    border-radius: 5px;
    background-color: var(--theme-splash-screen-chat-bg);
    margin: 0.875rem 0.437rem;
    padding: 0.75rem;
    cursor: pointer;
}

.grid_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
}

.grid_item {
    color: #fff;
    border: 1px solid var(--theme-border-color) !important;
    height: 58px;
    width: 370px;
    padding: 8px 12px;
    border-radius: 10px;
    opacity: 0;
    animation: fadeInAndBounce 0.4s ease forwards;
}

@media only screen and (max-width: 768px) {
    .grid_container {
        grid-template-rows: repeat(1, 1fr);
    }

    .example_title {
        font-size: 12px !important;
    }

    .example_sub_title {
        font-size: 12px !important;
    }

    .data_content {
        width: 95% !important;
    }

}

@media only screen and (max-width: 400px) {
    .grid_container {
        margin-bottom: 16px;
    }
}

@media only screen and (max-width: 1040PX) {
    .grid_item {
        width: 280px;
    }
}

@media only screen and (max-width: 840px) {
    .grid_item {
        width: 100%;
    }
}

.grid_item:hover {
    background-color: var(--theme-dummy-meessage-hove);
}

.grid_item:nth-child(1) {
    animation-delay: 0.2s;
}

.grid_item:nth-child(2) {
    animation-delay: 0.3s;
}

.grid_item:nth-child(3) {
    animation-delay: 0.4s;
}

.grid_item:nth-child(4) {
    animation-delay: 0.5s;
}

/* Keyframes for the fadeInAndBounce animation */
@keyframes fadeInAndBounce {
    0% {
        opacity: 0;
        transform: translateY(50px) scale(0.5);
    }

    50% {
        opacity: 0.7;
        transform: translateY(0) scale(1.02);
    }

    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

.example_wrapper {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
}

.example_title_container {
    display: flex;
    flex-direction: column;
}

.example_title {
    font-size: 14px;
    font-weight: 600;
    color: var(--theme-page-text)
}

.example_sub_title {
    font-size: 14px;
    font-weight: 500;
    color: var(--theme-gray)
}

.grid_item:hover .send_icon {
    display: block;
    background-color: var(--theme-send-icon-splash);
}

.send_icon {
    display: none;
    background-color: var(--theme-page-bg);
    width: 25px;
    height: 25px;
    padding: 6px;
    border-radius: 8px;
}

.connection_banner {
    display: flex;
    align-self: center;
}

.connection_banner>div {
    background-color: var(--theme-popup-field-bg);
    border: 1px solid var(--theme-popup-field-border);
    color: var(--theme-page-text);
    padding: 9px 17px;
    font-size: 17px;
    border-radius: 4px;
    cursor: pointer;
}


.connection_btn {
    position: relative;
    display: inline-block;
    margin: 20px 0;
    text-decoration: none;
    overflow: hidden;
}

.connection_btn:hover {
    background-color: var(--theme-btn-hover-bg);
    color: var(--theme-page-text);
}

.connection_btn span {
    position: absolute;
    display: block;
}

.connection_btn span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, white);
    animation: animate1 2s linear infinite;
}

@keyframes animate1 {
    0% {
        left: -100%;
    }

    50%,
    100% {
        left: 100%;
    }
}

.connection_btn span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, white);
    animation: animate2 2s linear infinite;
    animation-delay: 0.50s;
}

@keyframes animate2 {
    0% {
        top: -100%;
    }

    50%,
    100% {
        top: 100%;
    }
}

.connection_btn span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, white);
    animation: animate3 2s linear infinite;
    animation-delay: 1s;
}

@keyframes animate3 {
    0% {
        right: -100%;
    }

    50%,
    100% {
        right: 100%;
    }
}

.connection_btn span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, white);
    animation: animate4 2s linear infinite;
    animation-delay: 1.5s;
}

@keyframes animate4 {
    0% {
        bottom: -100%;
    }

    50%,
    100% {
        bottom: 100%;
    }
}

.logo_img_section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    background-color: white;
    border-radius: 100%;
    height: 70px;
    width: 70px;
    margin-bottom: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #5B5B5C;
}

.logo_img {
    width: 70%;
}