.database_view {
    position: absolute;
    right: 50px;
    top: 10%;
    width: "100%";
    width: 150px;
    height: 120px;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.database_view {
    background-color: #1A1B20;
}

.database_view>img {
    height: 85%;
    width: 85%;
}

.database_view>p {
    /* position: absolute; */
    margin: 0;
    padding: 0;
}


.database_view:hover {
    transform: scale(1.01);
}

.animated_dialog {
    background-color: transparent;
    /* animation: moveFromTopLeftToCenter 0.3s ease-in-out forwards; */
}

@keyframes moveFromTopLeftToCenter {
    0% {
        transform: translate(5%, -2%);
    }

    100% {
        transform: translate(0, 0);
    }
}

div:has(> a[aria-label="React Flow attribution"]) {
    display: none;
}