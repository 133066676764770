.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: var(--theme-popup-bg);
    z-index: 3;
    border-radius: 5px;
}

.popup_inner {
    position: absolute;
    left: 30%;
    right: 30%;
    top: 15%;
    bottom: 25%;
    margin: auto;
    background: var(--theme-popup-inner-bg);
    border-radius: 7px;
    padding: 1.5rem;
    height: fit-content;
    color: var(--theme-page-text);
}

.welcome_icon {
    justify-content: center;
    display: flex;
    margin-left: 16px;
}

.welcome_icon>svg {
    width: 350px;
    height: 350px;
}

.header {
    display: flex;
    font-size: 2rem;
    font-weight: 600;
    justify-content: center;
    margin-bottom: 10px;
}

.description {
    display: flex;
    justify-content: center;
}

.close_icon {
    color: var(--theme-icon-color);
    cursor: pointer;
}

.child_content {
    margin: 15px 0px;
}

.button_content {
    display: flex;
    justify-content: center;
}

.submit_btn {
    background-color: var(--theme-accept-button);
    color: var(--theme-accept-button-text);
    border: 0px;
    border-radius: 1px;
    padding: 10px 30px;
    font-weight: 600;
    cursor: pointer;
}


@media only screen and (max-width: 1100px) {
    .popup_inner {
        left: 18%;
        right: 18%;
    }
}

.header_content {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0px 1.5rem 15px 1.5rem;
    border-bottom: 1px solid var(--theme-page-text);
    margin: 0px -1.5rem;
}

.main_content {
    max-height: 400px;
    overflow-y: auto;
    padding: 0px 20px;
    font-size: 15px;
}

.footer {
    padding: 20px 1.5rem 0px 1.5rem;
    border-top: 1px solid var(--theme-page-text);
    margin: 0px -1.5rem;
    display: flex;
    justify-content: center;
}

.accept_button {
    background-color: var(--theme-accept-button);
    color: var(--theme-accept-button-text);
    border: 0px;
    border-radius: 20px;
    padding: 10px 40px;
    font-weight: 600;
    cursor: pointer;
}

.accept_button:disabled {
    cursor: not-allowed;
    background-color: var(--theme-accept-button-disabled);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.main_content::-webkit-scrollbar {
    width: 0px;
    height: 8px;
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.main_content {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.loader {
    width: 20px;
    height: 20px;
    margin: 0px auto 0;
    border: solid 4px var(--theme-page-text);
    ;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 1.0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 1.0s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}
