:root {
  --sidebar-width: 260px;
  --medium: 768px;
}
.app {
  background-color: var(--theme-root-bg);
  height: 100%;
  width: 100%;
}

.content {
  background-color: var(--theme-root-bg);
  height: 100%;
  width: 100%;
}

.light {
  --theme-root-bg: #0d0d0d;
  --theme-page-bg: white;
  --theme-page-bg-2: #000000;
  --theme-page-text: black;
  --theme-page-text-secondary: #d8d9db;
  --theme-page-text-input: #000;
  --theme-active-tab-bg: #2d2d2e;
  --theme-badge-bg: #4e4d4d;
  --theme-gradiant-start: #35374000;
  --theme-avtar-bg: #5d4037;
  --theme-avtar-workstream: #19c37d;
  --theme-avtar-workstream-border: #5ed5a4;
  --theme-avtar-user: #5d4037;
  --theme-avtar-user-border: #8a756f;
  --theme-chat-box-bg: white;
  --theme-error-banner-bg: #ef44441a;
  --theme-chat-error-text: #ececf1;
  --theme-error-banner-border: #ef4444;
  --theme-icon-color: #dfe1e4;
  --theme-popup-bg: #343541e6;
  --theme-popup-inner-bg: white;
  --theme-like-icon: #1a7f64;
  --theme-like-icon-bg: #d2f4d3;
  --theme-dislike-icon: #dc2626;
  --theme-dislike-icon-bg: #fee2e2;
  --theme-popup-field-bg: white;
  --theme-popup-submit-bg: white;
  --theme-popup-field-border: #565869;
  --theme-error-text: #f81e1e;
  --theme-splash-screen-bg: #222223;
  --theme-splash-screen-chat-bg: white;
  --theme-accept-button: #00bfa6;
  --theme-accept-button-text: white;
  --theme-accept-button-disabled: #8fcdc4;
  --theme-code-block-header: steelblue;
  --theme-code-block-hover-bg: cornflowerblue;
  --theme-textarea-bg: #3a3b3c;
  --theme-input-field-bg: transparent;
  --theme-input-field-border: #40414f;
  --theme-input-title: #6553bf;
  --theme-selected-navigation-item-bg: #3e3e42;
  --theme-navigation-item-hover-bg: #5b5b6e;
  --theme-navaigation-item-loader-bg: #505054;
  --theme-navigation-item-svg-hover-bg: white;
  --theme-delete-btn-bg: #ae5e5e;
  --theme-delete-btn-text: white;
  --theme-selected-chat-bg: #414a50;
  --theme-selected-chat-avtar-bg: #80909e;
  --theme-btn-hover-bg: #393a47;
  --theme-banner-bg: #f7f7f8;
  --theme-banner-text: white;
  --theme-save-btn-bg: white;
  --theme-sql-header-color: aliceblue;
  --theme-download-btn-color: white;
  --theme-download-btn-bg: white;
  --theme-table-header-bg: #242526;
  --theme-table-hader-text: white;
  --theme-table-column-bg: #343541e6;
  --theme-border-color: #555561;
  --theme-pin-color: black;
  --theme-white: white;
  --theme-gray: #5b5b5c;
  --theme-send-icon: #e5e5e5;
  --theme-send-icon-splash: #e5e5e5;
  --theme-dummy-meessage-hove: #f7f7f8;
  --theme-nav-left-header-bg: #f7f7f8;
  --theme-sidebar-hover: #5b5b5c;
  --theme-sidebar-list_hover: #2b2c2e;
  --theme-database-button: #343541;
  --theme-text-input-wrapper: #343541;
  --theme-settings-checked: #6553bf;
  --theme-settings-checked-text: #fff;
}

.dark {
  --theme-root-bg: #0d0d0d;
  --theme-page-bg: #343541;
  --theme-page-bg-2: #000000;
  --theme-page-text: white;
  --theme-page-text-secondary: #d8d9db;
  --theme-page-text-input: #d8d9db;
  --theme-active-tab-bg: #2d2d2e;
  --theme-badge-bg: #4e4d4d;
  --theme-gradiant-start: #35374000;
  --theme-avtar-bg: #5d4037;
  --theme-avtar-workstream: #19c37d;
  --theme-avtar-workstream-border: #5ed5a4;
  --theme-avtar-user: #5d4037;
  --theme-avtar-user-border: #8a756f;
  --theme-chat-box-bg: #343541;
  --theme-error-banner-bg: #ef44441a;
  --theme-chat-error-text: #ececf1;
  --theme-error-banner-border: #ef4444;
  --theme-icon-color: #dfe1e4;
  --theme-popup-bg: #343541e6;
  --theme-popup-inner-bg: #202123;
  --theme-like-icon: #1a7f64;
  --theme-like-icon-bg: #d2f4d3;
  --theme-dislike-icon: #dc2626;
  --theme-dislike-icon-bg: #fee2e2;
  --theme-popup-field-bg: #343541;
  --theme-popup-submit-bg: #343541;
  --theme-popup-field-border: #565869;
  --theme-error-text: #f81e1e;
  --theme-splash-screen-bg: #222223;
  --theme-splash-screen-chat-bg: #3e4042;
  --theme-accept-button: #00bfa6;
  --theme-accept-button-text: white;
  --theme-accept-button-disabled: #8fcdc4;
  --theme-code-block-header: steelblue;
  --theme-code-block-hover-bg: cornflowerblue;
  --theme-textarea-bg: #3a3b3c;
  --theme-input-field-bg: transparent;
  --theme-input-field-border: #40414f;
  --theme-input-title: #8e92b8;
  --theme-selected-navigation-item-bg: #3e3e42;
  --theme-navigation-item-hover-bg: #5b5b6e;
  --theme-navaigation-item-loader-bg: #505054;
  --theme-navigation-item-svg-hover-bg: #343541;
  --theme-delete-btn-bg: #ae5e5e;
  --theme-delete-btn-text: white;
  --theme-selected-chat-bg: #414a50;
  --theme-selected-chat-avtar-bg: #80909e;
  --theme-btn-hover-bg: #393a47;
  --theme-banner-bg: #40414f;
  --theme-banner-text: white;
  --theme-save-btn-bg: #343541;
  --theme-sql-header-color: aliceblue;
  --theme-download-btn-color: white;
  --theme-download-btn-bg: #343541;
  --theme-table-header-bg: #242526;
  --theme-table-hader-text: white;
  --theme-table-column-bg: #343541e6;
  --theme-border-color: #555561;
  --theme-pin-color: #ececf1;
  --theme-white: white;
  --theme-gray: #5b5b5c;
  --theme-send-icon: #494a54;
  --theme-send-icon-splash: #343541;
  --theme-dummy-meessage-hove: #393a47;
  --theme-nav-left-header-bg: #2e303b;
  --theme-sidebar-hover: #5b5b5c;
  --theme-sidebar-list_hover: #2b2c2e;
  --theme-database-button: #343541;
  --theme-text-input-wrapper: #e5e5e5;
  --theme-send-icon-hover-bg: #fff;
  --theme-settings-checked: #6553bf;
  --theme-settings-checked-text: #fff;
}

.root_colors {
  --white: #fff;
  --black: #000;
  --gray-50: #f9f9f9;
  --gray-100: #ececec;
  --gray-200: #e3e3e3;
  --gray-300: #cdcdcd;
  --gray-400: #b4b4b4;
  --gray-500: #9b9b9b;
  --gray-600: #676767;
  --gray-700: #424242;
  --gray-750: #2f2f2f;
  --gray-800: #212121;
  --gray-900: #171717;
  --gray-950: #0d0d0d;
  --red-500: #ef4444;
  --red-700: #b91c1c;
  --brand-purple: #ab68ff;
  --brand-dark-purple: #6553bf;
}

@value small: 734px;
@value medium: 868px;
