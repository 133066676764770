/* CustomSlider.css */
.slider_container {
  /* position: relative; */
  /* width: 80%; */
  /* margin: auto; */
}

.dots_container {
  display: flex;
  justify-content: flex-end;
  margin-top: -60px !important;
  margin-bottom: 65px !important;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: #5243aa;
  border-radius: 50%;
  display: inline-block;
}
.active {
  background-color: #b4b7ff;
}

/*  */

.container {
  /* width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background-color: white; */
  /* display: flex; */
  /* width: 100%; */
  /* height: 100%; */
  /* background-color: white; */
}
.textColor {
  /* background-color: white;
  display: flex; */
  /* height: 100%; */
  /* color: black; */
}

.team_sec {
  background-color: #6553bf;
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  min-height: 500px;
  position: relative;
  position: relative;
  overflow: hidden;
}
.team_tags {
  flex: 0 0 100%;
}

.team_tags button {
  color: #fff;
  text-decoration: none;
  background-color: #5243aa;
  padding: 15px;
  display: inline-flex;
  flex-wrap: wrap;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 18px;
  border: none;
  outline: none;
  cursor: pointer;
}

.skipbtn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #5243aa;
  text-decoration: none;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 20px;
  padding: 10px 30px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  font-weight: 700;
}

.skipbtnLeft {
  position: absolute;
  bottom: 20px;
  font-weight: 700;
  left: 20px;
  color: #5243aa;
  text-decoration: none;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 20px;
  padding: 10px 30px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
}

.skipAllBtn {
  position: absolute;
  bottom: 30px;
  left: 40px;
  color: #b4b7ff;
  text-decoration: none;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 20px;
  cursor: pointer;
}
.privacy {
  color: #6553bf;
  cursor: pointer;
}

/* Define styles for selected buttons */
.selectedButton {
  background-color: white !important; /* Selected background color */
  color: black !important; /* Selected text color */
  border-color: #007bff; /* Selected border color */
}
.transitioning {
  transition: opacity 3s ease;
}

/* question-slider.module.css */

.slide_content {
  opacity: 1;
  transition: opacity 0.5s ease; /* Define the transition property */
}

.fadeOut {
  opacity: 0;
}

.fadeIn {
  opacity: 1;
}

.card {
  width: 100%;
  /* background-color: #fff; */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-bottom: 15px;
  margin-top: 2px !important;
  background: var(--theme-white);
  color: var(--theme-page-bg-2);
}
.scroll {
  max-height: 800px; /* Adjust the height as needed */
  overflow-y: auto;
}

.cardTitle {
  font-weight: bold;
}

.update_btn {
  background-color: var(--theme-accept-button);
  color: var(--theme-accept-button-text);
  border: 0px;
  border-radius: 1px;
  padding: 10px 30px;
  font-weight: 600;
  cursor: pointer;
}

.viewUl {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex; /* Use flexbox */
  flex-wrap: wrap; /* Allow items to wrap to the next line */
}

.viewLi {
  margin-right: 4px; /* Adjust spacing between items */
}

.selected_known_buttons {
  color: #fff;
  text-decoration: none;
  background-color: #5243aa;
  padding: 15px;
  display: inline-flex;
  flex-wrap: wrap;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 18px;
  border: none;
  outline: none;
  cursor: pointer;
}

.specializations {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.answers_wrapper {
  height: 100%;
}

.header_answers {
  margin: 0;
  padding: 0;
  font-size: 30px;
}

.subtext_answers {
  margin: 0;
  padding: 0;
  margin: 0;
  font-weight: 500;
  padding: 0;
}

.header_titles {
  margin: 24px 0 0 0;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
}

.updatePreferencesWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 24px;
}

.updatePreferencesButton {
  background-color: var(--theme-accept-button);
  color: var(--theme-accept-button-text);
  border: 0px;
  border-radius: 4px;
  padding: 20px 30px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
}

.grid_loading {
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
}
