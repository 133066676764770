.root {
  box-sizing: border-box;
  max-height: 100vh;
  color: var(--theme-page-text-secondary);
  display: flex;
  flex-direction: column;
  background-color: var(--gray-900);
  justify-content: space-between;
  width: 0;
}

.root__pinned {
  min-width: var(--sidebar-width);
}

/* sidebar collapsed body */
.collapsed_body {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 0px;
  animation: collapsAnimation 0.3s ease-out;
}

@keyframes collapsAnimation {
  from {
    width: var(--sidebar-width);
  }

  to {
    width: 0px;
  }
}

/* Sidebar full not_collapsed_body */

.not_collapsed_body {
  box-sizing: border-box;
  background-color: var(--theme-page-bg-2);
  min-height: 100%;
  max-height: 100vh;
  min-width: 45px;
  width: 70px;
  transition: width 0.3s ease-out;
  animation: slide-in 0.2s;
}

.not_collapsed_body__not_visible {
  position: absolute !important;
  left: -100% !important;
}

@keyframes slide-in {
  0% {
    left: -100% !important;
    opacity: 0;
  }

  100% {
    left: 0 !important;
    opacity: 1;
  }
}

.not_collapsed_body__unpinned {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: var(--sidebar-width);
  transition: width 0.3s ease-in;

  box-shadow: 10px 3px 35px -9px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: 10px 3px 35px -9px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 10px 3px 35px -9px rgba(0, 0, 0, 0.51);
}

.not_collapsed_body__pinned {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 10px;
  position: relative;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  width: var(--sidebar-width);
  animation: notCollapsAnimation 0.3s ease-out;
}

.mobile_view_collapsed_true {
  /* overflow-x: hidden; */
}

.mobile_view_collapsed_false {
  overflow-x: hidden;
}

@keyframes notCollapsAnimation {
  from {
    width: 70px;
  }

  to {
    width: 100%;
  }
}

.not_collapsed_body_header {
  padding: 12px 10px 18px 10px;
  width: 100%;
  height: 80px;
}

.not_collapsed_body_header_wrap {
  display: flex;
}

.not_collapsed_body_header_icon {
  color: var(--theme-page-text-secondary);
  margin-right: 2px;
  width: 30px;
  height: 30px;
  margin-top: 2px;
}

.not_collapsed_body_header_text {
  font-weight: bold;
  align-items: center;
  display: flex;
  height: 80px;
  font-size: 20px;
}

.logo_wrapper {
  /* background-color: var(--brand-purple); */
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.logo_text {
  font-size: 30px;
  letter-spacing: 10px;
  text-transform: uppercase;
  margin-left: 20px;
}

.logo {
  height: 100%;
  -webkit-filter: invert(0.75); /* safari 6.0 - 9.0 */
  filter: invert(0.75);
}

/* collapsed body Header section */
.collapsed_body_header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

/* pinn button */

.pinn_button > svg {
  width: 21px;
  height: 21px;
}

.pinn_button {
  margin-top: 2px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: transparent;
  color: var(--theme-page-text-secondary);
  border: none;
  font-size: 1.5rem;
}

.pinn_button:hover {
  border-radius: 5px;
  background-color: var(--theme-active-tab-bg);
}

.menu_button {
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  height: 28px;
  color: var(--theme-page-text-secondary);
  border-radius: 25px;
  border: 0px;
  background-color: transparent;
  cursor: pointer;
}

.menu_item_icon {
  color: var(--white);
  font-size: 16px;
}

.main_content {
  height: calc(100vh - 280px);
  overflow-y: scroll;
  padding: 10px 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.main_content::-webkit-scrollbar {
  width: 0px;
  height: 8px;
  display: none;
}

.collapsed_root {
  align-items: center;
}

@media only screen and (max-width: 786px) {
  .not_collapsed_body_header {
    padding: 12px 2px 18px 2px;
  }

  .not_collapsed_body_header_text {
    font-size: 16px;
  }
}

.searchbar {
  margin: 5px 4px;
}

.search_div {
  background-color: var(--theme-active-tab-bg);
  padding: 10px 10px;
  margin: 0px 5px;
  font-size: 15px;
}

.loading_navigation_item {
  display: flex;
  align-items: start;
  justify-content: center;
  background: var(--theme-navaigation-item-loader-bg);
  padding: 3px 0px;
  border-radius: 5px;
  margin: 5px 6px;
}

.pin_icon {
  position: absolute;
  right: -29px;
  top: 49vh;
  opacity: 0.25;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.pin_icon:hover {
  opacity: 1;
}

.pin_icon1,
.pin_icon2 {
  height: 9px;
  width: 4px;
  margin-left: 10px;
  background-color: var(--theme-pin-color);
  transition: transform 0.3s ease-out;
  overflow: hidden;
}

.pin_icon1 {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  position: absolute;
  padding-top: 11px;
}

.pin_icon2 {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-bottom: 2px;
  position: absolute;
  margin-top: 10px;
}

.pin_icon:hover .p1 {
  transform: rotate(15deg);
}

.pin_icon:hover .p2 {
  transform: rotate(-15deg);
}

.collapsed_p1 {
  transform: rotate(-15deg);
}

.collapsed_p2 {
  transform: rotate(15deg);
}

.header_left_wrapper {
  position: absolute;
  right: -150px;
  top: 10px;
  z-index: 100;
}

@media only screen and (max-width: 786px) {
  .header_left_wrapper {
    top: 50px;
  }
}

.left_nav_header {
  display: flex;
  flex-direction: row;
  padding-inline: 8px;
  cursor: pointer;
}

.left_nav_header:hover {
  background-color: var(--theme-nav-left-header-bg);
  border-radius: 10px;
}

.arrow_down {
  font-size: 20px;
}

.header_title {
  font-size: 16px;
  line-height: 12px;
  font-weight: 600;
  color: var(--theme-page-text);
}

.version_text {
  margin-left: 10px;
  line-height: 12px;
  font-weight: 400;
  color: var(--theme-page-text);
}

.popover_content_dark {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  background-color: #202123;
}

.popover_content_light {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  background-color: white;
}

.popover_item_dark {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-inline: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.popover_item_light {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-inline: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.popover_item_dark:hover {
  background-color: #2e303b;
}

.popover_item_light:hover {
  background-color: #f7f7f8;
}

.content_title_dark {
  font-size: 16px;
  margin-left: 10px;
  color: white;
}

.content_title_light {
  font-size: 16px;
  margin-left: 10px;
  color: black;
}

.database_connections_title {
  font-weight: 700;
  width: 100%;
  font-size: 0.75rem;
  padding: 0 10px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add_icon {
  font-size: 16px;
}

.add_connection_button {
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  gap: 8px;
  color: var(--white);
  border-radius: 4px;
  border: none;
  background-color: var(--brand-purple);
  padding: 5px 10px;
  cursor: pointer;
}
