.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  /* background-color: var(--theme-popup-bg); */
  z-index: 100;
  border-radius: 5px;
}

.popup_inner {
  position: absolute;
  left: 1%;
  right: 1%;
  top: 1%;
  bottom: 1%;
  margin: auto;
  background: var(--theme-popup-inner-bg);
  border-radius: 7px;
  /* padding: 1.5rem; */
  height: auto;
  color: var(--theme-page-text);
}

.header {
  display: flex;
  justify-content: space-between;
  font-size: 1.125rem;
  font-weight: 500;
  /* color: var(--theme-page-text); */
}

.section_header {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  /* color: var(--theme-page-text); */
}

@media only screen and (max-width: 1100px) {
  .popup_inner {
    left: 18%;
    right: 18%;
  }
}

.header_content {
  font-size: 1.5rem;
  font-weight: 600;
  padding: 0px 1.5rem 15px 1.5rem;
  /* border-bottom: 1px solid var(--theme-page-text); */
  margin: 0px -1.5rem;
}

.main_content {
  max-height: 400px;
  overflow-y: auto;
  padding: 0px 20px;
  font-size: 15px;
}

.cancel_btn {
  background-color: var(--theme-download-btn-bg);
  border: 1px solid var(--theme-popup-field-border);
  color: var(--theme-download-btn-color);
  border: 0px;
  border-radius: 1px;
  padding: 10px 40px;
  font-weight: 600;
  cursor: pointer;
  margin-right: 8px;
}

.accept_button {
  background-color: var(--theme-accept-button);
  color: var(--theme-page-text);
  border: 0px;
  border-radius: 20px;
  padding: 10px 40px;
  font-weight: 600;
  cursor: pointer;
}

.accept_button:disabled {
  cursor: not-allowed;
  /* background-color: var(--theme-accept-button-disabled); */
}

.banner {
  display: flex;
  align-items: center;
  margin: 20px 0px;
  padding: 10px 9px;
  /* background: var(--theme-banner-bg); */
  /* color: var(--theme-banner-text); */
  border-radius: 5px;
  font-size: 15px;
}

.banner > svg {
  margin-right: 6px;
  height: 20px;
  width: 20px;
}

.field_container {
  max-height: 70vh;
  /* overflow-y: scroll; */
  padding-right: 4px;
}

.field_container > div {
  margin-bottom: 12px;
  width: 100% !important;
}

.button_content {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.submit_btn {
  background-color: var(--theme-accept-button);
  color: var(--theme-accept-button-text);
  border: 0px;
  border-radius: 1px;
  padding: 10px 30px;
  font-weight: 600;
  cursor: pointer;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.loader {
  width: 20px;
  height: 20px;
  margin: 0px auto 0;
  border: solid 4px var(--theme-page-text);
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.card {
  width: 100%;
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  padding: 15px;
  margin-bottom: 15px;
  margin-top: 2px !important;
  /* background: var(--theme-white); */
  color: var(--theme-page-text);
}

.cardTitle {
  font-weight: bold;
}
