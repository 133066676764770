.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: var(--theme-popup-bg);
    z-index: 3;
    border-radius: 5px;
}

.popup_inner {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: var(--theme-popup-inner-bg);
    border-radius: 7px;
    padding: 1.5rem;
    height: fit-content;
}

.popup_icons svg {
    padding: 5px 5px;
    border-radius: 20px;
    width: 25px;
    height: 25px;
}

.like {
    color: var(--theme-like-icon);
    background-color: var(--theme-like-icon-bg);
    margin-right: 20px;
}

.dislike {
    color: var(--theme-dislike-icon);
    background-color: var(--theme-dislike-icon-bg);
    margin-right: 20px;
}

.header {
    display: flex;
    justify-content: space-between;
    font-size: 1.125rem;
    font-weight: 500;
    color: var(--theme-page-text);
}

.header div {
    display: flex;
    align-items: center;
}

.close_icon {
    color: var(--theme-icon-color);
    cursor: pointer;
}

.child_content {
    margin: 15px 0px;
}

.button_content {
    display: flex;
    justify-content: end;
}

.submit_btn {
    background-color: var(--theme-popup-submit-bg);
    border: 1px solid var(--theme-popup-field-border);
    color: var(--theme-page-text);
    padding: 9px 17px;
    font-size: 0.875rem;
    border-radius: 4px;
    cursor: pointer;
}

.input_box {
    min-height: 90px;
    width: 100%;
    border-radius: 4px;
    background-color: var(--theme-popup-field-bg);
    border: 1px solid var(--theme-popup-field-border);
    font-size: 1rem;
    padding: 8px 12px;
    color: var(--theme-page-text);
    font-style: normal;
}

.input_box:focus {
    border: 1px solid var(--theme-page-text);
    border-radius: 4px;
}

.input_box:focus-visible {
    outline: 1px solid var(--theme-page-text);
    border-radius: 4px;

}

.extra_fields>label {
    margin: 7px 0px;
}

.extra_fields {
    margin: 5px 0px 10px 0px;
}

.ratings {
    margin-left: 5px;
}

.ratings :global .MuiRating-iconEmpty svg {
    color: var(--theme-popup-field-border);
}

.rating_container {
    margin-top: 8px;
    display: flex;
    align-items: center;
}

.error_text {
    font-size: 15px;
    color: var(--theme-error-text);
    margin-top: 5px;
}

@media only screen and (max-width: 1100px) {
    .popup_inner {
        left: 18%;
        right: 18%;
    }
}