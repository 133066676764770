.root {
  position: relative;
  width: 100%;

}

.root:hover {
  cursor: pointer;
}

.user {
  display: flex;
  align-items: center;
  justify-content: start;
}

.badge {
  background-color: var(--theme-avtar-bg);
  border-radius: 50%;
  color: var(--theme-page-text-secondary);
  font-size: 14px;
  height: 32px;
  min-width: 32px;
  line-height: 32px;
  text-align: center;
}

.user_names {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 48px;
  padding: 10px;
  border-radius: 10px;
}

.user_names_active{
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: #2B2C2E;
}


.user_names:hover{
  background-color: #2B2C2E;
}

.chevron {
  color: var(--theme-page-text-secondary);
  margin-top: 4px;
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

.name_wrap{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 10px;
}

.pinned_wrap{
  margin-left: -13px;
}

.bottom_sidebar_model {
  /* background-color: #343541; */
  z-index: 1;
  position: absolute;
  bottom: 70px;
  align-self: center;
  border-radius: 10px;
}

.model_title > span{
  color: white;
  font-size: .875rem
}

.logout_icon {
  color: aliceblue;
}

.divider {
  background-color: aliceblue;
}
