.option {
  font-size: 16px;
  min-width: 200px;
  text-align: left;
}

.option span {
  font-size: 16px;
  color: var(--theme-input-title);
}

.option svg:first-child {
  fill: var(--theme-input-field-border) !important;
}
