.root {
  position: relative;
  height: 100%;
  width: 100%;
  background: var(--theme-root-bg);
  /* overflow: overlay; */
  display: flex;
  /* gap: 15px; */
}

.left_section {
  /* min-width: 20%; */
  width: 20%;
  overflow-x: hidden;
  height: 100%;
  box-sizing: border-box;
  background: var(--theme-page-bg-2);
  /* border-bottom-right-radius: 5px; */
  /* border-top-right-radius: 5px; */
  transition-duration: .15s;
}

.collapsed_left_section {
  width: 45px;
  height: 100%;
  box-sizing: border-box;
  background: var(--theme-page-bg-2);
  /* border-bottom-right-radius: 5px; */
  /* border-top-right-radius: 5px; */
  transition-duration: .15s;
}

.middle_section {
  width: 30%;
  overflow: hidden;
  /* border-radius: 8px; */
  box-sizing: border-box;
  background: var(--theme-page-bg);
  margin: 10px 0px;
  transition-duration: .15s;
}

.wide_middle_section {
  width: 100%;
  overflow: hidden;
  /* border-radius: 8px; */
  /* box-sizing: border-box; */
  background: var(--theme-page-bg);
  /* margin: 10px 0px; */
  transition-duration: .15s;
}

.mid_section {
  width: -webkit-fill-available;
  /* border-radius: 8px; */
  box-sizing: border-box;
  background-color: var(--theme-splash-screen-bg);
  margin: 10px 0px;
  margin-right: 15px;
}

.right_section {
  /* min-width: 50%; */
  width: 50%;
  overflow: hidden;
  /* border-radius: 8px; */
  padding: 12px 4px 12px 12px;
  box-sizing: border-box;
  background: var(--theme-page-bg);
  margin: 10px 15px 10px 0px;
}

@media only screen and (max-width: 1200px) {
  /* .root {
    gap: 10px;
  } */

  .right_section {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  /* .root {
    gap: 5px;
  } */

  .right_section {
    margin-right: 5px;
  }
}

.unpinned_mid {
  width: 45%;
  /* border-radius: 8px; */
  box-sizing: border-box;
  background: var(--theme-page-bg);
  margin: 10px 0px;
  /* transition-duration: .15s; */
}

.collapsed_mid {
  width: 50%;
  /* border-radius: 8px; */
  box-sizing: border-box;
  background: var(--theme-page-bg);
  margin: 10px 0px;
  /* transition-duration: .15s; */
}