.label {
  font-weight: 400;
  font-size: 16px;
  line-height: 11px;
  display: flex;
  align-items: center;
  color: var(--theme-icon-color);
  position: relative;
  cursor: pointer;
  padding-left: 25px;
  height: 17px;
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: 2px solid var(--theme-icon-color);
  border-radius: 4px;
}

/* When the checkbox is checked, add a blue background */
.label input:checked~.checkmark {
  background-color: #3C72DA;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.label input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.label .checkmark:after {
  left: 3px;
  top: -1px;
  width: 4px;
  height: 8px;
  border: solid var(--theme-icon-color);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}