.sun_wrap {
  width: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 10px;
}

.moon_wrap {
  width: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 10px;
}