.input_wrap {
  width: 100%;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
  border: 1px solid var(--theme-input-field-border) !important;
  color: var(--theme-icon-color);
  background-color: var(--theme-input-field-bg);
}

.input_wrap fieldset {
  display: none;
}

.input_wrap input {
  height: 0.8rem;
  color: var(--theme-page-text-input);
  background-color: var(--theme-input-field-bg);
  font-size: 16px;
}

.label {
  font-size: 16px;
  color: var(--theme-input-title);
  margin-bottom: 3px;
  font-weight: 700;
}

.error_text {
  color: var(--theme-error-text);
  font-size: 14px;
  margin-top: 1px;
}

.icon {
  color: var(--theme-page-text-secondary);
  width: 20px;
  height: 20px;
}
