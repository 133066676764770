/* Navigation section */
.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.navigation_item {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.loading_navigation_item {
  display: flex;
  align-items: start;
  justify-content: center;
  background: var(--theme-navaigation-item-loader-bg);
  padding: 3px 0px;
  border-radius: 5px;
  margin: 5px 6px;
}

.navigation_item_body {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 1rem;
  background-color: transparent;
  width: 100%;
  color: var(--theme-page-text-secondary);
  border: none;
  margin-bottom: 6px;
  cursor: pointer;
  height: 43px;
  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
}

.navigation_item_body:hover {
  border-radius: 5px;
  background-color: var(--theme-sidebar-list_hover);
}

.navigation_item_body__active {
  border-radius: 5px;
  background-color: var(--theme-active-tab-bg);
}

.navigation_item_icon {
  font-size: 16px;
  margin-right: 2px;
  margin-top: 2px;
  min-width: 18px;
  color: var(--theme-sidebar-hover);
}

.navigation_sub_item_icon {
  font-size: 12px;
  margin-right: 2px;
  margin-top: 2px;
  min-width: 18px;
  color: white;
}

.navigation_item_title {
  margin-left: 7px;
  font-size: 16px;
  margin-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--theme-sidebar-hover);
}

.navigation_sub_item_title {
  margin-left: 7px;
  font-size: 12px;
  margin-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
}

.navigation_subitems {
  padding-left: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.navigation_subitems_arrow svg:hover {
  background-color: var(--theme-popup-field-bg);
}

.selected_item {
  border-radius: 5px;
  background-color: var(--theme-selected-navigation-item-bg);
}

.overflow_text {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.navigation_item_body:hover .overflow_text {
  width: calc(100% - 78px);
}

.active_menu {
  border-left: 3px solid var(--theme-page-text);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.normal_menu {
  border-left: 3px solid transparent;
  /* border-top-left-radius: 0px;
  border-bottom-left-radius: 0px; */
}

.action_btn_group {
  display: none;
}

.navigation_item_body:hover .action_btn_group {
  display: flex;
  /* position: absolute;
  right: 41px;
  background: #5b5c6f;
  padding-left: 10px;
  border-radius: 5px; */
}

.action_btn_group>svg {
  width: 27px;
  height: 27px;
  padding: 4px;
  border-radius: 5px;
}

.action_btn_group>svg:hover {
  background-color: var(--theme-navigation-item-svg-hover-bg);
}

.hide_item{
  display: none;
}