.loading_root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.sidebar {
  width: 250px;
  height: 100%;
  padding: 16px;
}

.sidebar_grid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.root {
  width: 100%;
  height: 100%;
}

.content {
  width: calc(100% - 250px);
  height: 100%;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.text {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.icon {
}

.textarea {
}

.dashboard_content {
  background-color: var(--theme-root-bg);
  height: 100%;
  width: 100%;
}

.loading_root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app_name {
  font-size: 100px;
  width: 100%;
  margin: 0;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 80px;
}

.logo_wrapper {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  animation: scale 1s ease alternate-reverse infinite;
}

.logo {
  -webkit-filter: invert(1); /* safari 6.0 - 9.0 */
  filter: invert(1);
  height: 50%;
}

@keyframes scale {
  to {
    transform: scale(1.1);
  }
}
